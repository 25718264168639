import React from 'react'
import Campaign from '@mui/icons-material/Campaign'

export const permitMenu = {
  agent: 'AGENT',
  manager: 'Manager',
  agc: 'AGC',
  cs: 'CS',
  staff: 'STAFF',
}

export const subMenuAnnounce = [
  {
    label: 'ประกาศผู้มีสิทธิ์สอบ',
    href: '/announce-examiner',
    icon: <Campaign />,
  },
  {
    label: 'ประกาศผู้มีสิทธิ์อบรม E-Learning',
    href: '/learners',
    icon: <Campaign />,
  },
]

export const subMenuELearning = [
  {
    label: 'หลักสูตรทั้งหมด (e-Learning)',
    href: '/e-learning/course',
  },
  {
    label: 'เส้นทางการเรียนรู้ทั้งหมด',
    href: '/e-learning/learning-path',
  },
  {
    label: 'Collection ทั้งหมด',
    href: '/e-learning/collection',
  },
]

export const menuList = [
  { label: 'หลักสูตรทั้งหมด', href: '/course' },
  {
    label: 'สมัครสอบ',
    onClick: () => {
      localStorage.removeItem('prospectDetail')
      window.location.href = '/e-examination/'
    },
    href: '/e-examination/',
  },
  {
    label: 'E-Learning',
    type: 'menu',
    isDesk: true,
    subMenu: subMenuELearning,
  },
  { ...subMenuELearning[0], isMobile: true },
  { label: 'คลังความรู้', href: '/e-content' },
  { label: 'ประกาศผล', type: 'menu', isDesk: true, subMenu: subMenuAnnounce },
  { ...subMenuAnnounce[0], isMobile: true },
  { ...subMenuAnnounce[1], isMobile: true },
  {
    label: 'กรอกใบสมัคร',
    onClick: () => {
      window.location.href = '/dms/full-form'
    },
  },
]

export const noLoginMenuList = [
  { label: 'เข้าสู่ระบบ', href: '/login' },
  { label: 'ติดต่อเรา', href: '/location' },
]

import _ from 'lodash'
import dayjs from 'dayjs'
import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'
import {
  E_LEARNING_LEARNER_LOG_RESULT,
  MODULE_TYPE,
} from '../../../../constants/eLearning'
import {
  setInitialPreview as setInitialPreviewETest,
  setAnswered,
  setNextQuestion,
  setPreviousQuestion,
  setSectionResult,
  setSectionTesting,
  resetOnViewState,
  resetAnswer,
} from '../../eTesting'
import {
  setInitialPreview as setInitialPreviewEva,
  setAnswerEEvaluation,
  setNextQuestion as setNextQuestionEva,
  setPreviousQuestion as setPreviousQuestionEva,
  setSectionResult as setSectionResultEva,
  setSectionEvaluate,
  resetAnswer as resetAnswerEva,
} from '../../eEvaluation'
import {
  getLatestETestEEva,
  handleEEvaluationProgress,
  handleETestingProgress,
  handleEmbedContentProgress,
  handleUpdateLogLearnerPerLesson,
  handleUploadFileProgress,
  isProgressDiff,
  setLatestETestEEva,
} from './events'
import {
  resetModuleProgress,
  setEnableModule,
  setModuleProgress,
  setSelectedFile,
  setSelectedLesson,
  setStartModule,
  setSubmitModule,
  setVideoState,
  stopLessonLoading,
} from '.'
import { getETesting } from 'src/services/eTesting'

export const listenerMiddlewareELearning = createListenerMiddleware()

// handle Embed Content progress
listenerMiddlewareELearning.startListening({
  actionCreator: setVideoState,
  effect: (action, listenerApi) => {
    const { type, payload } = action
    if (type.includes('setVideoState') && _.isNil(payload.played)) return

    const progress = handleEmbedContentProgress(payload)
    if (isProgressDiff(listenerApi, progress)) {
      listenerApi.dispatch(setModuleProgress(Number(progress)))
    }
  },
})

// handle Upload File progress
listenerMiddlewareELearning.startListening({
  matcher: isAnyOf(setSelectedLesson, setSelectedFile),
  effect: (action, listenerApi) => {
    const { selectedLesson } = listenerApi.getState().eLearningLearning
    if (selectedLesson.moduleType !== MODULE_TYPE.UPLOAD_FILE) return

    const progress = handleUploadFileProgress(selectedLesson)
    if (isProgressDiff(listenerApi, progress)) {
      listenerApi.dispatch(setModuleProgress(Number(progress)))
    }
  },
})

// handle E-Testing progress
listenerMiddlewareELearning.startListening({
  matcher: isAnyOf(
    stopLessonLoading,
    setStartModule,
    setAnswered,
    setNextQuestion,
    setPreviousQuestion,
    setSectionResult
  ),
  effect: (action, listenerApi) => {
    const { selectedLesson } = listenerApi.getState().eLearningLearning
    if (selectedLesson.moduleType !== MODULE_TYPE.E_TESTING) return
    if (selectedLesson.isSubmitted && !_.isEmpty(selectedLesson.endTime)) return
    if (!selectedLesson.isInProgress) return

    const eTesting = listenerApi.getState().eTesting
    const progress = handleETestingProgress(eTesting)
    if (isProgressDiff(listenerApi, progress)) {
      listenerApi.dispatch(setModuleProgress(progress))
    }
  },
})

// handle E-Evaluation progress
listenerMiddlewareELearning.startListening({
  matcher: isAnyOf(
    setAnswerEEvaluation,
    setNextQuestionEva,
    setPreviousQuestionEva,
    setSectionResultEva
  ),
  effect: (action, listenerApi) => {
    const { type } = action
    if (type.includes('setSectionResult')) {
      listenerApi.dispatch(setModuleProgress(100))
      return
    }

    const eEvaluation = listenerApi.getState().eEvaluation
    const progress = handleEEvaluationProgress(eEvaluation)
    if (isProgressDiff(listenerApi, progress)) {
      listenerApi.dispatch(setModuleProgress(progress))
    }
  },
})

// handle E-Testing/E-Eva State and Storage
listenerMiddlewareELearning.startListening({
  actionCreator: setSelectedLesson,
  effect: (action, listenerApi) => {
    const oldELearningPreview = listenerApi.getOriginalState().eLearningLearning
    const { eLearningLearning } = listenerApi.getState()
    const oldSelected = _.get(oldELearningPreview, 'selectedLesson', {})
    const selected = _.get(eLearningLearning, 'selectedLesson', {})
    const oldType = _.get(oldELearningPreview, 'selectedLesson.type', '')
    const type = _.get(selected, 'type', '')

    if (oldSelected.isInProgress) {
      if (oldType === MODULE_TYPE.E_TESTING) {
        setLatestETestEEva(listenerApi, 'eTesting')
      }
      if (oldType === MODULE_TYPE.E_EVALUATION) {
        setLatestETestEEva(listenerApi, 'eEvaluation')
      }
    }

    if (type === MODULE_TYPE.E_TESTING) {
      if (!selected.isSubmitted) {
        listenerApi.dispatch(setSectionTesting())
        listenerApi.dispatch(resetOnViewState())
      }

      const latestETestState = getLatestETestEEva(listenerApi)
      if (!_.isEmpty(latestETestState)) {
        listenerApi.dispatch(setInitialPreviewETest(latestETestState))
      }
    }

    if (type === MODULE_TYPE.E_EVALUATION) {
      if (!selected.isSubmitted) listenerApi.dispatch(setSectionEvaluate())

      const latestEEvaState = getLatestETestEEva(listenerApi)
      if (!_.isEmpty(latestEEvaState)) {
        listenerApi.dispatch(setInitialPreviewEva(latestEEvaState))
      }
    }
  },
})

// handle enable next module
listenerMiddlewareELearning.startListening({
  actionCreator: setModuleProgress,
  effect: async ({ payload }, listenerApi) => {
    const { selectedLesson } = listenerApi.getState().eLearningLearning
    const type = _.get(selectedLesson, 'moduleType', '')
    const isETesting = type === MODULE_TYPE.E_TESTING
    const isEEva = type === MODULE_TYPE.E_EVALUATION
    if (payload === 100 && !isETesting && !isEEva) {
      listenerApi.dispatch(setEnableModule())
    }
  },
})

listenerMiddlewareELearning.startListening({
  actionCreator: setStartModule,
  effect: async (action, listenerApi) => {
    const { selectedLesson, eLearningCourseBatchLearner } =
      listenerApi.getState().eLearningLearning
    if (selectedLesson.isSubmitted) return

    const type = _.get(selectedLesson, 'moduleType', '')
    const isETesting = type === MODULE_TYPE.E_TESTING
    const startTime = dayjs().format('YYYY-MM-DD HH:mm:ss').toString()

    // handle update log when start e-test/e-eva
    if (isETesting) {
      const uuid = _.get(selectedLesson, 'eTestingUuid', '')
      const moduleUuid = _.get(selectedLesson, 'uuid', '')
      const payload = {
        uuid,
        moduleUuid,
        startTime,
        eLearningCourseBatchLearner,
        isModule: true,
      }
      listenerApi.dispatch(resetAnswer())
      await listenerApi.dispatch(getETesting(payload))
    }

    if (type === MODULE_TYPE.E_EVALUATION) {
      listenerApi.dispatch(resetAnswerEva())
    }

    // handle update log when start e-test/e-eva
    const body = {
      logLearnerPerLessonUuid: _.get(selectedLesson, 'logUuid', ''),
      data: {
        percent: 0,
        result: E_LEARNING_LEARNER_LOG_RESULT.INPROGRESS,
        startTime,
        endTime: null,
      },
    }
    if (isETesting) {
      body.data = { ...body.data, score: null, totalScore: null }
    }
    await listenerApi.dispatch(handleUpdateLogLearnerPerLesson(body))
  },
})

// handle update log when submit e-testing
listenerMiddlewareELearning.startListening({
  type: 'eTesting/getETestingScore/fulfilled',
  effect: async ({ payload }, listenerApi) => {
    const { selectedLesson } = listenerApi.getState().eLearningLearning
    if (_.isEmpty(selectedLesson)) return

    const isSubmitted = _.get(selectedLesson, 'isSubmitted', '')
    if (isSubmitted) return

    const response = _.get(payload, 'data', {})
    let percent = 100
    let result = E_LEARNING_LEARNER_LOG_RESULT.PASS
    if (!response.isPass) {
      percent = 0
      result = E_LEARNING_LEARNER_LOG_RESULT.FAIL
      listenerApi.dispatch(resetModuleProgress())
    } else {
      listenerApi.dispatch(setEnableModule())
    }
    listenerApi.dispatch(setModuleProgress(percent))
    listenerApi.dispatch(setSubmitModule(true))

    const body = {
      logLearnerPerLessonUuid: _.get(selectedLesson, 'logUuid', ''),
      data: {
        percent,
        result,
        startTime: _.get(selectedLesson, 'startTime', ''),
        endTime: dayjs().format('YYYY-MM-DD HH:mm:ss').toString(),
        score: _.get(response, 'learnerScore', ''),
        totalScore: _.get(response, 'totalScore', ''),
      },
    }
    await listenerApi.dispatch(handleUpdateLogLearnerPerLesson(body))
  },
})

// handle e-testing time out
listenerMiddlewareELearning.startListening({
  type: 'eTesting/getETesting/rejected',
  effect: async ({ meta }, listenerApi) => {
    const { selectedLesson } = listenerApi.getState().eLearningLearning
    if (_.isEmpty(selectedLesson)) return
    if (selectedLesson.result === E_LEARNING_LEARNER_LOG_RESULT.FAIL) return

    if (_.get(meta.response.data, 'property', '').includes('TIME_OUT')) {
      const body = {
        logLearnerPerLessonUuid: _.get(selectedLesson, 'logUuid', ''),
        data: {
          percent: 0,
          result: E_LEARNING_LEARNER_LOG_RESULT.FAIL,
          startTime: _.get(selectedLesson, 'startTime', ''),
          endTime: null,
          score: null,
          totalScore: null,
        },
      }
      await listenerApi.dispatch(handleUpdateLogLearnerPerLesson(body))
    }
  },
})

// handle update log when submit e-evaluation
listenerMiddlewareELearning.startListening({
  type: 'eEvaluation/postEEvaluationSubmit/fulfilled',
  effect: async (action, listenerApi) => {
    const { selectedLesson } = listenerApi.getState().eLearningLearning
    if (_.isEmpty(selectedLesson)) return

    const isSubmitted = _.get(selectedLesson, 'isSubmitted', '')
    if (isSubmitted) return

    listenerApi.dispatch(setModuleProgress(100))
    listenerApi.dispatch(setEnableModule())
    listenerApi.dispatch(setSubmitModule(true))
    const body = {
      logLearnerPerLessonUuid: _.get(selectedLesson, 'logUuid', ''),
      data: {
        percent: 100,
        result: E_LEARNING_LEARNER_LOG_RESULT.COMPLETE,
        startTime: _.get(selectedLesson, 'startTime', ''),
        endTime: dayjs().format('YYYY-MM-DD HH:mm:ss').toString(),
      },
    }
    await listenerApi.dispatch(handleUpdateLogLearnerPerLesson(body))
  },
})

import React from 'react'
import dayjs from 'dayjs'
import _ from 'lodash'
import screenfull from 'screenfull'
import { startLoading, stopLoading } from 'src/redux/slices/loading'
import {
  setChangeCamera,
  openDialog as openVerifyDialog,
  setVideoState,
  setRandomTime,
  setRound,
  resetTime,
  setRandomDateTime,
  setFailTime,
  setFailMaxTimes,
  setFullScreen,
} from 'src/redux/slices/eLearning/learning'
import { MODULE_TYPE } from 'src/constants/eLearning'
import { getCheckBatchFailScanCount } from 'src/services/eLearning/detail'
import { openDialog } from 'src/redux/slices/alertDialog'
import { SEC_PER_MIN } from 'src/constants'
import { handleCalculateTimeLeft } from '../../Enroll/Step2/events'
import { getLearningState } from '../events'
import DisallowDialog from '../DisallowDialog'

export const handleCheckAllowScan = (isDialogOpen) => async (dispatch) => {
  if (!isDialogOpen) return

  const { eLearningCourseBatchLearner } = getLearningState()
  const uuid = _.get(eLearningCourseBatchLearner, 'uuid', '')
  dispatch(startLoading())
  const response = await dispatch(getCheckBatchFailScanCount(uuid))
  dispatch(stopLoading())
  const expireDateTime = _.get(response.payload.data, 'registerBreakTime', '')
  if (!_.isEmpty(expireDateTime)) {
    dispatch(handleDisallowScan(response))
  }
}

export const handleDisallowScan = (response) => (dispatch) => {
  const expireDateTime = _.get(response.payload.data, 'registerBreakTime', '')
  const time = handleCalculateTimeLeft(expireDateTime)
  dispatch(setFailTime(time))
  const failTimes = _.get(response.payload.data, 'countRegister', '')
  dispatch(setFailMaxTimes(failTimes))
  dispatch(
    openDialog({
      isCloseDialog: false,
      type: 'content',
      content: <DisallowDialog />,
    })
  )
}

export const checkDuplicate = (previous, number) => {
  const isDup = previous.includes(number)
  if (isDup) return checkDuplicate(previous, _.random(1, 30))
  return number
}

export const checkCompareTimeRemain = (times) => {
  const time = getLearningState().time
  const timeRemain = _.get(time, 'timeRemain.face', SEC_PER_MIN)
  const minRemain = timeRemain / SEC_PER_MIN

  const newTimes = []
  times.forEach((num, index) => {
    if (index === 0) newTimes.push(num)
    else {
      const previous = newTimes[index - 1]
      const isGreater = num - previous > minRemain
      if (isGreater) newTimes.push(num)
      else newTimes.push(previous + minRemain + 1)
    }
  })
  return newTimes
}

export const handleRandomTime = () => (dispatch) => {
  const { verifyRound } = getLearningState().verifyDialog
  let times = []
  _.forEach(Array(verifyRound).fill(), (e, index) => {
    let number = _.random(1, 30)
    if (index > 0) number = checkDuplicate(times, number)
    times.push(number)
  })
  times = checkCompareTimeRemain(times.sort((a, b) => a - b))
  dispatch(setRound(0))
  dispatch(setRandomTime(times))
}

export const handleToggleTime = () => {
  const { randomTime, round } = getLearningState().verifyDialog
  const time = randomTime[round] || 1
  if (round === 0) return time
  return time - randomTime[round - 1]
}

export const handleFormatNow = () => {
  return `${dayjs(new Date()).format(window.__env__.REACT_APP_DATE_DB)} ${dayjs(
    new Date()
  ).format(window.__env__.REACT_APP_DATE_TIME)}`
}

export const handleOpenDialog = (isNotSm) => (dispatch) => {
  const { verifyRound, round } = getLearningState().verifyDialog

  const nextRound = round + 1
  if (nextRound > verifyRound) return

  if (isNotSm) screenfull.exit()

  dispatch(setFullScreen(false))
  dispatch(setRound(nextRound))
  dispatch(resetTime())
  dispatch(setRandomDateTime(handleFormatNow()))
  dispatch(setChangeCamera({ key: 'isOpen', value: false }))
  dispatch(openVerifyDialog())

  const moduleType = _.get(getLearningState().selectedLesson, 'moduleType', '')
  if (moduleType === MODULE_TYPE.EMBED_CONTENT) {
    dispatch(setVideoState({ playing: false }))
  }
}

import React from 'react'
import _ from 'lodash'
import { useHistory, useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Typography from '@mui/material/Typography'
import { Column } from 'src/components/Layout/Styled'
import {
  E_LEARNING_LEARNER_RESULT,
  E_LEARNING_LEARNER_STATUS,
} from 'src/constants/eLearning'
import { StyledBigButton } from './Styled'
import { handleEnrollment, handleGetInClass } from './events'

const EnrollButton = ({ color, scrolled }) => {
  const eLearningCourse = useSelector(
    (state) => state.eLearningDetail.data,
    shallowEqual
  )
  const learnerStatus = _.get(eLearningCourse, 'learnerStatus', undefined)
  const learnerResult = _.get(eLearningCourse, 'learnerResult', undefined)
  const isLearnerFinished = !_.isEmpty(learnerResult)
  const isEnrollOpen = _.get(eLearningCourse, 'isEnrollOpen', '')
  const isLearningButtonEnable = _.get(
    eLearningCourse,
    'isLearningButtonEnable',
    false
  )
  const dispatch = useDispatch()
  const history = useHistory()
  const isVerify = _.get(eLearningCourse, 'isVerify', false)

  // รอการยืนยันตัวตน
  if (isVerify) {
    const uuid = _.get(
      eLearningCourse,
      'eLearningCourseBatchLearner.eLearningCourseBatch.uuid',
      ''
    )
    const batchLearnerUuid = _.get(
      eLearningCourse,
      'eLearningCourseBatchLearner.uuid',
      ''
    )
    return (
      <StyledBigButton
        onClick={() =>
          (window.location.href = `/e-learning/course/enroll/verification/${uuid}?learner=${batchLearnerUuid}`)
        }
      >
        ยืนยันตัวตน
      </StyledBigButton>
    )
  }

  // ไม่เคยสมัคร และสามารถสมัครได้
  if (_.isUndefined(learnerStatus)) {
    // เปิดให้สมัคร
    if (isEnrollOpen) return <SubmitEnrollButton />
    // ยังไม่เปิดให้สมัคร
    return <EnrollCloseButton />
  }

  // เรียนจบ
  if (isLearnerFinished) {
    // บังคับลงทะเบียนอีกครั้งจาก Learning path
    if (isEnrollOpen) return <SubmitEnrollButton />
    // เรียนผ่าน
    if (learnerResult === E_LEARNING_LEARNER_RESULT.PASS) {
      return (
        <StyledBigButton disabled>
          ไม่สามารถลงทะเบียนเรียนได้ เนื่องจากคุณผ่านการอบรมแล้ว
        </StyledBigButton>
      )
    }
  }

  switch (learnerStatus) {
    // คุณสมบัติไม่ผ่าน
    case E_LEARNING_LEARNER_STATUS.INELIGIBLE:
      return (
        <Column gap="10px" alignItems="center">
          <StyledBigButton scrolled={scrolled} disabled>
            ไม่สามารถลงทะเบียนเรียนได้ เนื่องจากไม่ผ่านคุณสมบัติเบื้องต้น
          </StyledBigButton>
          {!scrolled && (
            <Typography color={color || 'text.white'} textAlign="center">
              หากต้องการสอบถามข้อมูลเพิ่มเติม กรุณาติดต่อเจ้าหน้าที่ KTAXA
            </Typography>
          )}
        </Column>
      )
    // ประกาศผลการสมัครแล้ว
    case E_LEARNING_LEARNER_STATUS.ELIGIBLE_ANNOUNCE: {
      // อยู่ในระหว่างเรียน
      return (
        <StyledBigButton
          disabled={!isLearningButtonEnable}
          onClick={() => dispatch(handleGetInClass(history))}
        >
          เริ่มเรียน
        </StyledBigButton>
      )
    }
    case E_LEARNING_LEARNER_STATUS.WAITING_ANNOUNCE:
      return <StyledBigButton disabled>รอประกาศสิทธิ์</StyledBigButton>
    default:
      return <EnrollCloseButton />
  }
}

export default EnrollButton

export const SubmitEnrollButton = () => {
  const { id: uuid } = useParams()
  const dispatch = useDispatch()
  return (
    <StyledBigButton onClick={() => dispatch(handleEnrollment(uuid))}>
      ลงทะเบียน
    </StyledBigButton>
  )
}

export const EnrollCloseButton = () => {
  return <StyledBigButton disabled>ปิดรับสมัคร</StyledBigButton>
}

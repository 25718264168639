import { closeDialog, openDialog } from 'src/redux/slices/alertDialog'
import { getLogoutEvent } from 'src/services/logout'
import { setFilter } from 'src/redux/slices/eLearning/learningPath/list'
import { E_LEARNING_STATUS } from 'src/constants/eLearning'
import { path } from 'src/constants/path'

export const handleClick = (event, setAnchorEl) => {
  setAnchorEl(event.currentTarget)
}

export const handleClose = (setAnchorEl) => {
  setAnchorEl(null)
}

const userType = {
  AGENT: 'Agent',
  STAFF: 'Staff',
  PROSPECT: 'Prospect',
}

const LOCAL = ['DEV', 'LOCAL']

export const handleLogout =
  (auth, type, location = `/login`) =>
  (dispatch) => {
    dispatch(
      openDialog({
        title: 'ออกจากระบบ',
        message: ['คุณต้องการออกจากระบบ?'],
        buttonLeft: { label: 'ยกเลิก' },
        buttonRight: {
          label: 'ยืนยัน',
          handleClick: async () => {
            await dispatch(getLogoutEvent())
            handleConfirmLogout(auth, type, location)
            dispatch(closeDialog())
          },
        },
      })
    )
  }

export const handleConfirmLogout = (auth, type, location = '') => {
  const classUuid = localStorage.getItem('classUuid')
  window.localStorage.clear()
  window.sessionStorage.clear()
  localStorage.setItem('classUuid', classUuid)

  const { __env__: env } = window

  const redirectTo = {
    post_logout_redirect_uri: `${env.REACT_APP_APP_URL}${location}`,
  }

  switch (type) {
    case userType.AGENT:
      handleRedirectAgent(env, auth, redirectTo)
      break
    case userType.STAFF:
      handleRedirectStaff(env, auth, redirectTo)
      break
    default:
      auth.signoutRedirect(redirectTo)
      break
  }
}

export const handleRedirectAgent = (env, auth, redirectTo) => {
  if (!LOCAL.includes(env.ENV)) {
    const url_logout = `${env.REACT_APP_LOGOUT_URL_B2A}`
    const wt = window.open(
      url_logout,
      'agent',
      'width=10,height=1,left=5,top=3'
    )
    setTimeout(() => {
      wt?.close()
      auth.signoutRedirect(redirectTo)
      window?.location?.reload()
    }, 500)
    return false
  } else {
    auth.signoutRedirect(redirectTo)
    window?.location?.reload()
  }
}

export const handleRedirectStaff = (env, auth, redirectTo) => {
  if (!LOCAL.includes(env.ENV)) {
    window.location.href = `${window.__env__.REACT_APP_LOGOUT_URL_B2E}`
    return false
  } else {
    auth.signoutRedirect(redirectTo)
    window?.location?.reload()
  }
}

export const redirectToBackend = () => {
  window.location.href = `${window.__env__.REACT_APP_BACKEND_URL}/switch-from-learner`
}

export const redirectToLearningPath = (history) => (dispatch) => {
  const payload = {
    key: 'myLearningPath',
    value: [E_LEARNING_STATUS.INPROGRESS],
  }
  dispatch(setFilter(payload))
  history.push(path.learningPath)
}

export const redirectToDMS = () => {
  window.location.href = `/dms/full-form`
}
